import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { HighlightedBlog } from './useHighlightedBlogs'
import './HighlightedBlogs.scss'

interface Props {
  blogs?: HighlightedBlog[]
  lang?: string
  title?: string
}

const HighlightedBlogs: React.FC<Props> = ({ blogs, lang, title }) => {
  return (
    <>
      {title && <h2 className="heading">{title}</h2>}

      <div className="highlighted-blogs">
        {blogs?.map(blog => {
          return (
            <Link key={blog.blogUrl} to={blog.blogUrl}>
              <div className="blog">
                <GatsbyImage
                  image={blog.image}
                  alt="blog-logo"
                  className="icon"
                />
                <div className="text">
                  <div className="title">
                    {lang === 'hindi' ? blog.titleHindi : blog.title}
                  </div>
                  <div className="description">
                    {lang === 'hindi'
                      ? blog.descriptionHindi
                      : blog.description}
                  </div>
                </div>
              </div>
            </Link>
          )
        })}
      </div>
    </>
  )
}

export default HighlightedBlogs
