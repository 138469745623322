import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import './Poker.scss'
import { Container } from 'react-bootstrap'

import Videos from './Videos'
import GameTestimonials from './GameTestimonials'
import OtherPracticeGames from './OtherPracticeGames'
import DownloadBottomSheet from '../../../Download/DownloadBottomSheet'

import PokerPracticeInfo from '../../CategoryAndGamesInfo/PokerPracticeInfo'
import HomeFAQs from '../../../Index/HomeFAQs/HomeFAQs'
import useVideos from '../../videos-list'
import { Faq } from '../../../FAQs/faqs-list-interface'
import useTestimonials from '../../../Testimonials/useTestimonials'
import { POKER } from '../../../download-links'
import InfoCard from '../../CategoryAndGamesInfo/InfoCard'
import { recsPracticeGameNames } from './useRecommendPracticeGames'
import HighlightedBlogs from '../../HighlightedBlogs/HighlightedBlogs'
import useHighlightedBlogs from '../../HighlightedBlogs/useHighlightedBlogs'
import PokerCertifications from './Certifications'
import PaymentPartners from '../../../Index/PaymentPartners/PaymentPartners'
import Features from '../../../Index/Features/Features'
import SafeAndCertified from '../../../Index/SafeAndCertified/SafeAndCertified'
import MainBannerArea from '../../../Index/MainScreen/Desktop/MainBannerArea'

const PracticePoker: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      intro: file(relativePath: { eq: "game-banners/Texas.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      mobBanner: file(
        relativePath: { eq: "poker-banner/mobPracticeBanner.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      deskBanner: file(
        relativePath: { eq: "poker-banner/deskPracticeBanner.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 840, layout: CONSTRAINED)
        }
      }
    }
  `)
  const pokerBanner = [
    {
      id: 1,
      mob: data.mobBanner.childImageSharp.gatsbyImageData,
      desk: data.deskBanner.childImageSharp.gatsbyImageData,
      background: '#000000',
    },
  ]

  const [pokerFaqsSt, setPokerFaqsSt] = useState<Faq[]>([])

  useEffect(() => {
    import('../../../FAQs/faqs-list').then(({ pokerPracticeFaqs }) =>
      setPokerFaqsSt(pokerPracticeFaqs)
    )
  }, [])

  const [showDownload, setShowDownload] = useState(false)
  const hideBottomSheet = (): void => {
    setShowDownload(false)
  }

  const { pokerTestimonials } = useTestimonials()
  const { pokerVideos } = useVideos()
  const blogs = useHighlightedBlogs('Poker')

  return (
    <div id="poker">
      <MainBannerArea
        multipleBanner={pokerBanner}
        customClass="home-banner"
        pageName="poker-practice"
      />
      <Videos videos={pokerVideos} />
      <Container>
        <HighlightedBlogs blogs={blogs} />
        <InfoCard>
          <PokerPracticeInfo />
        </InfoCard>
      </Container>

      <PokerCertifications />
      <PaymentPartners />
      <div className="d-none d-md-block">
        <Features gameName="poker" />
      </div>
      <SafeAndCertified hideGame />
      <GameTestimonials testimonials={pokerTestimonials} />
      <div className="faqs-wrap">
        <HomeFAQs faqs={pokerFaqsSt} />
      </div>
      <OtherPracticeGames
        gameName={recsPracticeGameNames.PracticePoker}
        category="Cards"
      />
      <DownloadBottomSheet
        show={showDownload}
        hideBottomSheet={hideBottomSheet}
        downloadLink={POKER}
      />
    </div>
  )
}

export default PracticePoker
