import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PracticePoker from '../components/Games/Cards/Poker/PracticePoker'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import { POKER_PRACTICE } from '../components/internal-links'
import { POKER } from '../components/download-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generatePokerTrailerSchema,
  generatePokerTutorialsVideoSchema,
  generatePokerReviewSchemaAndroid,
  generatePokerReviewSchemaIos,
  generateOrgSchema,
  generateWebsiteSchema,
} from '../lib/generate-schema'
import { Faq } from '../components/FAQs/faqs-list-interface'
import { pokerOneLinksPokerPage } from '../components/one-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Poker Free Practice App',
    url: POKER_PRACTICE,
  },
]

const PokerPractice: React.FC = () => {
  const [pokerFaqsSt, setPokerFaqsSt] = useState<Faq[]>([])

  useEffect(() => {
    import('../components/FAQs/faqs-list').then(({ pokerFaqs }) =>
      setPokerFaqsSt(pokerFaqs)
    )
  }, [])

  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      apkLink={POKER}
      showPokerForm
      pokerOneLink={pokerOneLinksPokerPage}
      customLogo
    >
      <Helmet>
        {process.env.GATSBY_STAGING === 'true' && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <SEO
        title="Free Practice Poker Online"
        description="Free Practice Poker Online. Play Free Entry Tournaments on Mega Poker. Download the Free Practice App Now."
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        reviewSchemaPokerAndroid={generatePokerReviewSchemaAndroid()}
        reviewSchemaPokerIos={generatePokerReviewSchemaIos()}
        faqSchema={generateFaqSchema(pokerFaqsSt)}
        videoSchema={[
          generatePokerTrailerSchema(),
          ...generatePokerTutorialsVideoSchema(),
        ]}
      />
      <PracticePoker />
    </Layout>
  )
}

export default PokerPractice
