import { Faq } from '../FAQs/faqs-list-interface'

export const rngFaqs: Faq[] = [
  {
    question: 'What is RNG ?',
    answer: [
      'RNG, short for Random Number Generator, is the system used to create unpredictable patterns that generate a completely random distribution of cards for the Poker or Rummy games online.',
    ],
  },
  {
    question: 'What does a certified RNG mean for you?',
    answer: [
      'A certified RNG ensures that any deck of cards used in a the game play is completely unpredictable and guess-proof. It ensures that no player can predict the cards or get better cards than others by design.',
      'Testing an RNG requires sophisticated analysis over a large data samples. Many countries (though not India yet) require RNG to be tested and certified by specialised agencies. Our RNG has been certified one of world’s leading agencies in the field - iTech Labs, Australia. Click below to view the certificate.',
    ],
  },
]

export const aigfFaqs: Faq[] = [
  {
    question: 'What is AIGF?',
    answer: [
      'All India Gaming Federation is the apex industry body for skill gaming industry in India, ensuring the global best practices in the online gaming industry in India, and maintaining transparency, integrity and responsible gaming.',
      'We are a member of the All India Gaming Federation, and have built a platform where safety and security are of utmost importance.',
    ],
  },
  {
    question: 'What does it mean for you?',
    answer: [
      'It means that as a professionally run, regulated and responsible skill gaming platform, we ensure that we maintain the highest standards of trust and safety, and we take responsible gaming seriously.',
      'We feature only proven games of skill - no luck or lotteries, they only way you can win on Mega is with your skills. We strive to protect players who are at risk of potential negative effects of gaming through various means, including player age verification and guidance on responsible gaming.',
    ],
  },
]

export const pgfFaqs: Faq[] = [
  {
    question: 'What is Poker Fairness Certificate?',
    answer: [
      'How do you know the game you are playing is fair? How do you everyone is on a level playing field? How do you know the gameplay corresponds to the standard rules of the games, and the rules have been implemented correctly?',
      'To ensure that the game you are playing is fair and follows the standard rule of play, it is essential that the game is evaluated & verified by independent and accredited bodies against technical standards of the game.',
      'Our Hold Em’ Poker has been independently tested & certified by iTech Labs Australia, one of the leading accredited testing laboratories for online gaming worldwide, against international technical standards for Texas Holdem. This includes game rules & artwork evaluation, functional testing, payable verification as well as source code review. ',
    ],
  },
  {
    question: 'What does Poker Fairness Certificate means to you?',
    answer: [
      'We understand that in order for Poker to be fun, players need to know that the games they are playing are fair. It is essential that you know that all players are playing with the same level of information and playing by the rules.',
      'The game evaluation certificate ensures that our Hold Em’ Poker is fair and in compliance with industry standards in highly regulated jurisdictions, so that the only thing you need to think about it playing, winning and having fun without any worries. ',
    ],
  },
]
export const pgfFaqsHindi: Faq[] = [
  {
    question: 'पोकर फेयरनेस सर्टिफिकेट क्या है?',
    answer: [
      'आप कैसे जानते हैं कि आप जो खेल खेल रहे हैं वह निष्पक्ष है? आप सभी एक समान खेल के मैदान में कैसे हैं? आप कैसे जानते हैं कि गेमप्ले गेम के मानक नियमों से मेल खाता है, और नियमों को सही तरीके से लागू किया गया है?',
      'यह सुनिश्चित करने के लिए कि आप जो खेल खेल रहे हैं वह निष्पक्ष है और खेल के मानक नियमों का पालन करता है, यह आवश्यक है कि खेल के तकनीकी मानकों के विरुद्ध स्वतंत्र और मान्यता प्राप्त निकायों द्वारा खेल का मूल्यांकन और सत्यापन किया जाए।',
      `हमारे होल्ड एम' पोकर को टेक्सास होल्डम के लिए अंतरराष्ट्रीय तकनीकी मानकों के खिलाफ दुनिया भर में ऑनलाइन गेमिंग के लिए अग्रणी मान्यता प्राप्त परीक्षण प्रयोगशालाओं में से एक, आईटेक लैब्स ऑस्ट्रेलिया द्वारा स्वतंत्र रूप से परीक्षण और प्रमाणित किया गया है। इसमें खेल के नियम और कलाकृति का मूल्यांकन, कार्यात्मक परीक्षण, देय सत्यापन के साथ-साथ स्रोत कोड की समीक्षा शामिल है।,`,
    ],
  },
  {
    question: 'पोकर फेयरनेस सर्टिफिकेट का आपके लिए क्या मतलब है?',
    answer: [
      'हम समझते हैं कि पोकर को मज़ेदार बनाने के लिए, खिलाड़ियों को यह जानना होगा कि वे जो खेल खेल रहे हैं वे निष्पक्ष हैं। यह जानना आवश्यक है कि सभी खिलाड़ी समान स्तर की जानकारी के साथ खेल रहे हैं और नियमों के अनुसार खेल रहे हैं।',
      `गेम मूल्यांकन प्रमाणपत्र यह सुनिश्चित करता है कि हमारा होल्ड एम' पोकर निष्पक्ष है और उच्च विनियमित न्यायालयों में उद्योग मानकों के अनुपालन में है, ताकि आपको बिना किसी चिंता के खेलने, जीतने और मज़े करने के बारे में सोचने की आवश्यकता हो।`,
    ],
  },
]
