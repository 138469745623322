import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import RngFaq from '../../../Certifications/RngFaq'
import { pgfFaqs, pgfFaqsHindi } from '../../../Certifications/cert-faqs'
import './Certifications.scss'
import CertFeatures from '../../../Certifications/CertFeatures'
import useCertFeatures from '../../../Certifications/useCertFeatures'

interface Props {
  lang?: string
}

const PokerCertifications: React.FC<Props> = ({ lang }) => {
  const { pgfFeatures, pgfFeaturesHindi } = useCertFeatures()

  const data = useStaticQuery(graphql`
    {
      pfc: file(relativePath: { eq: "iTechLabsLogo.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 87, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <>
      <div className="certifications-wrap">
        <section>
          <Container>
            <h2 className="mb-18">
              {lang === 'hindi'
                ? 'पोकर गेम फेयरनेस सर्टिफिकेट'
                : 'Poker Game Fairness Certificate'}
            </h2>
            <Row>
              <Col md={9}>
                <RngFaq faqs={lang === 'hindi' ? pgfFaqsHindi : pgfFaqs} />

                <div className="cert">
                  <div className="img">
                    <GatsbyImage
                      image={data.pfc.childImageSharp.gatsbyImageData}
                      alt="Poker Game Fairness Certificate"
                      imgStyle={{ objectFit: 'contain' }}
                    />
                  </div>
                  <div className="cta">
                    <div className="comp">
                      {lang === 'hindi'
                        ? 'पोकर गेम फेयरनेस सर्टिफिकेट देखें'
                        : 'View Poker Game Fairness Certificate'}
                    </div>
                    <div className="btns">
                      <a
                        href="https://itechlabs.com/certificates/GetMega/GetMega_UK_Game_Certificate_19May2021.pdf"
                        className="btn-view"
                        rel="noopener noreferrer nofollow"
                        target="_blank"
                      >
                        {lang === 'hindi' ? 'उसे देखें' : 'View'}
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="c-features">
              <Col md={9}>
                <Row xs={1} md={3}>
                  <CertFeatures
                    featureItems={
                      lang === 'hindi' ? pgfFeaturesHindi : pgfFeatures
                    }
                  />
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  )
}

export default PokerCertifications
