import React from 'react'
import useFaqExpansion from '../../hooks/useFaqExpansion'
import FAQ from '../FAQs/FAQ'
import { Faq } from '../FAQs/faqs-list-interface'
import './Certifications.scss'

interface Props {
  faqs: Faq[]
}

const RngFaq: React.FC<Props> = ({ faqs }) => {
  const { expandedFaq, setExpandedFaq } = useFaqExpansion()

  return (
    <div className="faq">
      {faqs.map((f, i) => {
        return (
          <FAQ
            question={f.question}
            answer={f.answer}
            key={f.question}
            expanded={expandedFaq === i}
            setExpanded={setExpandedFaq}
            questinNumber={i}
          />
        )
      })}
    </div>
  )
}

export default RngFaq
