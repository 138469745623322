import { graphql, useStaticQuery } from 'gatsby'
import { CertFeaturesProps } from './CertFeatures'

const useCertFeatures = (): {
  rngFeatures: CertFeaturesProps[]
  aigfFeatures: CertFeaturesProps[]
  pgfFeatures: CertFeaturesProps[]
  pgfFeaturesHindi: CertFeaturesProps[]
} => {
  const data = useStaticQuery(graphql`
    {
      unpredictable: file(
        relativePath: { eq: "Certifications/unpredictable.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 180, layout: CONSTRAINED)
        }
      }
      fairplay: file(relativePath: { eq: "Certifications/fairplay.png" }) {
        childImageSharp {
          gatsbyImageData(width: 180, layout: CONSTRAINED)
        }
      }
      random: file(relativePath: { eq: "Certifications/random.png" }) {
        childImageSharp {
          gatsbyImageData(width: 180, layout: CONSTRAINED)
        }
      }
      skill: file(relativePath: { eq: "Certifications/skill.png" }) {
        childImageSharp {
          gatsbyImageData(width: 180, layout: CONSTRAINED)
        }
      }
      responsible: file(
        relativePath: { eq: "Certifications/responsible.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 180, layout: CONSTRAINED)
        }
      }
      trust: file(relativePath: { eq: "Certifications/trust.png" }) {
        childImageSharp {
          gatsbyImageData(width: 180, layout: CONSTRAINED)
        }
      }
      certified: file(
        relativePath: { eq: "Certifications/certified_games.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 180, layout: CONSTRAINED)
        }
      }
      standardised: file(
        relativePath: { eq: "Certifications/standardised_rules.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 180, layout: CONSTRAINED)
        }
      }
      fairgameplay: file(
        relativePath: { eq: "Certifications/fair_gameplay.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 180, layout: CONSTRAINED)
        }
      }
    }
  `)

  const rngFeatures: CertFeaturesProps[] = [
    {
      id: 1,
      imageUrl: data.unpredictable.childImageSharp.gatsbyImageData,
      title: 'Random Outcome',
      text: 'Our RNG produces a completely random stream of numbers that can be called at any point of time.',
    },
    {
      id: 2,
      imageUrl: data.fairplay.childImageSharp.gatsbyImageData,
      title: 'Fair Card Shuffle',
      text: 'The random distribution created by the RNG are used to create a completely unpredictable card patterns - for a completely fair shuffle, ensuring true fair-play.',
    },
    {
      id: 3,
      imageUrl: data.random.childImageSharp.gatsbyImageData,
      title: 'Encrypted Card Decks',
      text: 'The cards decks in our card games are 100% safe and encrypted, so that they truly can&apos;t be seen by anyone - neither us nor anyone else. Nobody on the table knows what’s on the cards, till the cards are on the table!',
    },
  ]

  const aigfFeatures: CertFeaturesProps[] = [
    {
      id: 1,
      imageUrl: data.skill.childImageSharp.gatsbyImageData,
      title: 'Games of Skill',
      text: 'Mega features only proven games of skill. No guessing, no gambling — the only way you win is with your skills.',
    },
    {
      id: 2,
      imageUrl: data.responsible.childImageSharp.gatsbyImageData,
      title: 'Responsible Gaming',
      text: 'Only players of verified legal age (18+) allowed. Assistance &amp; protection of players through guidance.',
    },
    {
      id: 3,
      imageUrl: data.trust.childImageSharp.gatsbyImageData,
      title: 'Safety and Trust',
      text: 'We have built safe and secure payment channels for seamless withdrawals, and maintain the highest standards of data protection and privacy, along with certified gameplay mechanics to deliver the safest and most trustworthy gaming experience online.',
    },
  ]

  const pgfFeatures: CertFeaturesProps[] = [
    {
      id: 1,
      imageUrl: data.certified.childImageSharp.gatsbyImageData,
      title: 'Certified Games',
      text: 'Functionally tested, reviewed and certified games ensure that everything is exactly where it supposed to be, the only thing you only have to think about is playing the game and not worry about anything else.',
    },
    {
      id: 2,
      imageUrl: data.standardised.childImageSharp.gatsbyImageData,
      title: 'Standardised Rules',
      text: 'Evaluated & reviewed against international technical  standards of the game, the certification ensures that gameplay everyone knows, understands and plays by the standard rules of the game.',
    },
    {
      id: 3,
      imageUrl: data.fairgameplay.childImageSharp.gatsbyImageData,
      title: 'Fair Gaming',
      text: 'Independently evaluated &amp; certified games ensures that everyone who plays is on a level playing field and playing by the same exact set of rules.',
    },
  ]
  const pgfFeaturesHindi: CertFeaturesProps[] = [
    {
      id: 1,
      imageUrl: data.certified.childImageSharp.gatsbyImageData,
      title: 'प्रमाणित खेल',
      text: 'कार्यात्मक रूप से परीक्षण किए गए, समीक्षा किए गए और प्रमाणित गेम सुनिश्चित करते हैं कि सब कुछ ठीक वही है जहां यह होना चाहिए था, केवल एक चीज जिसके बारे में आपको केवल गेम खेलना है और किसी और चीज के बारे में चिंता नहीं करनी है।',
    },
    {
      id: 2,
      imageUrl: data.standardised.childImageSharp.gatsbyImageData,
      title: 'मानकीकृत नियम',
      text: 'खेल के अंतरराष्ट्रीय तकनीकी मानकों के खिलाफ मूल्यांकन और समीक्षा की गई, प्रमाणन यह सुनिश्चित करता है कि गेमप्ले हर कोई खेल के मानक नियमों को जानता, समझता और खेलता है।',
    },
    {
      id: 3,
      imageUrl: data.fairgameplay.childImageSharp.gatsbyImageData,
      title: 'फेयर गेमिंग',
      text: 'स्वतंत्र रूप से मूल्यांकन किया गया; प्रमाणित खेल यह सुनिश्चित करते हैं कि खेलने वाला प्रत्येक व्यक्ति समान खेल मैदान पर है और नियमों के समान सटीक सेट द्वारा खेल रहा है।',
    },
  ]

  return {
    rngFeatures,
    aigfFeatures,
    pgfFeatures,
    pgfFeaturesHindi,
  }
}

export default useCertFeatures
