import { graphql, useStaticQuery } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import * as links from '../../internal-links'

export interface HighlightedBlog {
  title: string
  titleHindi?: string
  description: string
  descriptionHindi?: string
  image: IGatsbyImageData
  blogUrl: string
}

const useHighlightedBlogs = (gameName: string) => {
  const data = useStaticQuery(graphql`
    {
      PokerBooks: file(
        relativePath: { eq: "Highlighted Blogs/poker books.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 60, layout: CONSTRAINED)
        }
      }
      PokerLearn: file(
        relativePath: { eq: "Highlighted Blogs/poker learn.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 60, layout: CONSTRAINED)
        }
      }
      PokerStrategy: file(
        relativePath: { eq: "Highlighted Blogs/poker strategy.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 60, layout: CONSTRAINED)
        }
      }
      PokerTerms: file(
        relativePath: { eq: "Highlighted Blogs/poker terms.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 60, layout: CONSTRAINED)
        }
      }
    }
  `)

  const pokerBlogs: HighlightedBlog[] = [
    {
      title: 'Learn Poker',
      titleHindi: 'पोकर सीखें',
      description: 'All you need to know about Poker',
      descriptionHindi: 'पोकर के बारे में आप सभी को पता होना चाहिए',
      image: data.PokerLearn.childImageSharp.gatsbyImageData,
      blogUrl: links.POKER_LEARN,
    },
    {
      title: 'Poker Terms',
      titleHindi: 'पोकर शर्तें',
      description: 'A-Z of Poker terms',
      descriptionHindi: 'पोकर शर्तों का A-Z',
      image: data.PokerTerms.childImageSharp.gatsbyImageData,
      blogUrl: links.POKER_TERMS,
    },
    {
      title: 'Poker Strategy',
      titleHindi: 'पोकर रणनीति',
      description: 'Because strategy is everything',
      descriptionHindi: 'क्योंकि रणनीति ही सब कुछ है',
      image: data.PokerStrategy.childImageSharp.gatsbyImageData,
      blogUrl: links.POKER_STRATEGY,
    },
    {
      title: 'Poker Books',
      titleHindi: 'पोकर किताबें',
      description: 'Best books to learn poker',
      descriptionHindi: 'पोकर सीखने के लिए सर्वश्रेष्ठ पुस्तकें',
      image: data.PokerBooks.childImageSharp.gatsbyImageData,
      blogUrl: links.POKER_BOOKS,
    },
  ]

  const gameHighlightedBlogs: Record<string, HighlightedBlog[]> = {
    Poker: pokerBlogs,
  }

  return gameHighlightedBlogs[gameName]
}

export default useHighlightedBlogs
