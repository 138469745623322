import React from 'react'
import { Col } from 'react-bootstrap'
import { GatsbyImage } from 'gatsby-plugin-image'
import './cert-features.scss'

export interface CertFeaturesProps {
  id: number
  imageUrl: []
  title: string
  text: string
}

interface Props {
  featureItems: CertFeaturesProps[]
}

const CertFeatures: React.FC<Props> = ({ featureItems }) => {
  return (
    <>
      {featureItems.map(item => (
        <Col className="c-feature" key={item.id}>
          <div className="feature-img">
            <GatsbyImage
              image={item.imageUrl}
              alt={item.title}
              imgStyle={{ objectFit: 'contain' }}
            />
          </div>
          <div className="text">
            <h3> {item.title}</h3>
            <p>{item.text}</p>
          </div>
        </Col>
      ))}
    </>
  )
}

export default CertFeatures
